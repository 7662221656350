import Api from "../apis/Api";
import ActionModalMixin from "./ActionModalMixin";

const BackupActionsMixin = {
  mixins: [ActionModalMixin],
  methods: {
    // DELETE ACTION
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.backups.delete.title', {count: 1});
      this.modalOptions.message = this.$t('heading.instance.backups.delete.message', {
        count: 1,
        name: `<b>${item.name}</b>`
      });
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";
      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.backup', {count: 1}),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];
      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: () => {
          this.deleteBackup(item);
        },
      });
      this.modalOptions.item = item;
      this.modalOptions.open = true;
    },
    deleteBackup(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(`/instances/${this.instance.id}/wordpress/backups/${item.id}`)
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.backup.delete.success')
          });
          this.modalClose();
          item.async_status.delete = "pending";
          // this.reloadBackups();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showMassDeleteModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.backups.delete.title', {count: 2});
      this.modalOptions.message = this.$t('heading.instance.backups.delete.message', {count: 2});
      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: () => {
          this.massDeleteBackup();
        },
      });
      this.modalOptions.open = true;
    },
    async massDeleteBackup() {
      if (process.env.VUE_APP_DEMO_MODE) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: "Action not available in demo mode.",
        });
        return;
      }
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      let i = 0;
      for (const item of this.checkedItems) {
        i++;
        this.modalOptions.submittingProgressLog += `\n Deleting backup ${i}/${this.checkedItems.length}...`;
        let result = await Api.delete(
          `/instances/${this.instance.id}/wordpress/backups/${item}`
        )
          .then(() => {
            this.modalOptions.submittingProgressLog += `\n Deleted backup ${i}/${this.checkedItems.length}.`;
            return true;
          })
          .catch((error) => {
            this.modalOptions.submittingProgressLog += `\n Error deleting backup ${i}/${this.checkedItems.length}: `;
            this.modalOptions.submittingProgressLog +=
              Api.getErrorMessage(error);
            this.modalOptions.submittingError += Api.getErrorMessage(error);

            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: this.modalOptions.submittingError,
            });
            return false;
          });
        if (!result) {
          break;
        }
      }
      if (!this.modalOptions.submittingError) {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t('notification.backup.delete.completed')
        });
        this.modalClose();
        this.checkedItems = [];
      }

      this.modalOptions.open = false;
      this.modalOptions.persistent = false;
      this.modalOptions.submitting = false;
      this.reloadBackups();
    },

    // CREATE ACTION
    showCreateModal(retryId = null) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.instance.backups.create.title')
      this.modalOptions.icon = "$update";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.create'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.createBackup(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.backupDirectory'),
          tooltip: 'tooltip.create_backup.backup_directory',
          name: "backupDirectory",
          type: "checkbox",
        },
        {
          label: this.$t('form.label.backupDatabase'),
          tooltip: 'tooltip.create_backup.backup_database',
          name: "backupDatabase",
          type: "checkbox",
        },
        {
          label: this.$t('form.label.backupNote'),
          tooltip: "tooltip.create_backup.backup_note",
          name: "backupNote",
          type: "textarea",
        },
      ];
      this.modalOptions.item = {
        backupDirectory: true,
        backupDatabase: true,
      };
      if (Number.isInteger(retryId)) {
        this.modalOptions.item.retry_id = retryId;
      }
      this.modalOptions.open = true;
    },
    createBackup: function (formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(`/instances/${this.instance.id}/wordpress/backups`, formData)
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.backup.create.success')
          });
          this.modalClose();
          if (this.$route.name === 'Backups') {
            this.reloadBackups();
          }
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // RESTORE ACTION
    showRestoreModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.instance.backups.restore.title')
      this.modalOptions.icon = "$restore";

      this.modalOptions.buttons.unshift({
        label: this.$t('button.restore'),
        color: "primary",
        onclick: (modal) => {
          if (
            this.$refs.basicModal.formValues.restoreDirectory ||
            this.$refs.basicModal.formValues.restoreDatabase
          ) {
            this.restoreBackup(item, modal.formValues);
          } else {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage:
                this.$t('notification.instance.backup.restore.error')
            });
          }
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.restoreDatabase'),
          name: "restoreDatabase",
          type: "checkbox",
        },
        {
          label: this.$t('form.label.restoreDirectory'),
          name: "restoreDirectory",
          type: "checkbox",
        },
        {
          label: this.$t('form.label.deleteExistingFiles'),
          name: "deleteExistingFiles",
          type: "checkbox",
        },
      ];
      this.modalOptions.open = true;
    },
    restoreBackup(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";
      Api.put(
        `/instances/${this.instance.id}/wordpress/backups/${item.id}/restore`,
        formData
      )
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.backup.restore.success')
          });
          this.modalClose();
          item.async_status.restore = "pending";
          // this.reloadBackups();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    repeatAction(item) {
      if (item.async_status.create == "failed") {
        this.showCreateModal(item.id);
      }
      if (item.async_status.delete == "failed") {
        this.showDeleteModal(item);
      }
      if (item.async_status.restore == "failed") {
        this.showRestoreModal(item);
      }
    },

    // DOWNLOAD ACTIONs
    downloadBackup(item) {
      if (process.env.VUE_APP_DEMO_MODE) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: "Action not available in demo mode.",
        });
        return;
      }
      // eslint-disable-next-line
      let apiUrl = this.$store.state.env.apiUrl;
      window.open(
        `${apiUrl}/instances/${this.instance.id}/wordpress/backups/${item.id}/download`,
        "_blank"
      );
    },
  },
};

export default BackupActionsMixin;
